var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"observer",attrs:{"rules":{ required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('div',{attrs:{"id":"client-transactions"}},[_c('b-row',[_c('b-col',{attrs:{"lg":"3"}},[_c('b-card-actions',{attrs:{"action-collapse":"","title":"Φίλτρα"}},[_c('validation-provider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{class:_vm.getValidationClass(validationContext),attrs:{"label":_vm.$t('generic.client')}},[_c('v-select',{attrs:{"options":_vm.$store.getters.getAllContacts,"label":"surname"},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(_vm._s(_vm.$t('errors.no-matching')))]},proxy:true},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.surname)+" "+_vm._s(option.name)+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.surname)+" "+_vm._s(option.name)+" ")]}}],null,true),model:{value:(_vm.form.contactId),callback:function ($$v) {_vm.$set(_vm.form, "contactId", $$v)},expression:"form.contactId"}})],1),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0]))])]}}],null,true)}),_c('b-form-group',{attrs:{"label":_vm.$t('generic.from-1'),"label-for":"start-date"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"start-date","config":{
					allowInput: true,
                  altInput: true,
                  altFormat: 'd-m-Y',
                  dateFormat: 'Y-m-d',
                },"value":null},model:{value:(_vm.form.from),callback:function ($$v) {_vm.$set(_vm.form, "from", $$v)},expression:"form.from"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('generic.to-1'),"label-for":"end-date"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"end-date","config":{
					allowInput: true,
                  altInput: true,
                  altFormat: 'd-m-Y',
                  dateFormat: 'Y-m-d',
                },"value":null},model:{value:(_vm.form.to),callback:function ($$v) {_vm.$set(_vm.form, "to", $$v)},expression:"form.to"}})],1),_c('b-button',{staticClass:"mt-2",attrs:{"disabled":invalid,"block":"","variant":"outline-primary"},on:{"click":_vm.generateReport}},[_c('span',[_vm._v(_vm._s(_vm.$t("generic.view-results")))])]),_c('b-button',{attrs:{"block":"","variant":"flat-warning"},on:{"click":_vm.reset}},[_vm._v(" Καθαρισμός ")])],1)],1),_c('b-col',{attrs:{"lg":"9"}},[_c('b-card',{attrs:{"no-body":""}},[(_vm.showPdf)?_c('b-row',{staticStyle:{"height":"100vh"}},[_c('vue-pdf-app',{staticClass:"w-100 h-100",attrs:{"pdf":_vm.$store.getters.getReport}})],1):_vm._e()],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }