import _ from "lodash";

export default {
    methods: {
        resetField(form) {
            Object.keys(form).forEach((key, index) => form[key] = null);
        },

        deleteEmptyProperties(form, value = null) {
            Object.keys(form).forEach((key, index) => {
                if (form[key] === null) {
                    delete form[key];
                }
            });
        },
        getDefaultImage() {
            return require('@/assets/images/avatars/default.jpeg')
        },

        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },

        clearEmptiesObject(o) {
            for (var k in o) {
                if (!o[k] || typeof o[k] !== "object") {
                    continue // If null or not an object, skip to the next iteration
                }

                // The property is an object
                if (Object.keys(o[k]).length === 0) {
                    delete o[k]; // The object had no properties, so delete that property
                }
                return o;
            }
        },

        removeEmpty(obj) {

            return _.omitBy(obj, (v, idx) => {
                if (idx === 'deadline' && !v) {
                    return null;
                }
                if (idx !== 'photo' || idx !== 'partners' || idx !== 'deadline' || idx!=='repeatable') {
                    return _.isUndefined(v) || _.isNull(v) || v == '' || v == "";
                }
            });
        },

        removeEmptySecond(obj) {

            Object.keys(obj).forEach(key => {
                if (key !== 'photo' || key !== 'partners' || key !=='repeatable') {
                    if (_.isUndefined(obj[key]) || _.isNull(obj[key]) || obj[key] == '' || obj[key] == "") {
                        delete obj[key];
                    }
                }
            });
        },


        removeValidatorIcon(value) {

            if (this.user[value]?.length === 0 || this.user[value] == undefined) {
                setTimeout(() => {
                    this.$refs[value].$el.classList.remove('is-valid')
                    this.$refs[value].$el.parentElement.classList.remove('is-valid')
                }, 400)

            }
        },
    },


};