<template>
   <div>
	  <validation-observer ref="observer"
						   v-slot="{handleSubmit , invalid}"
						   :rules="{ required: true}">
		 <div id="client-transactions">
			<b-row>
			   <b-col lg="3">
				  <!-- Filters -->
				  <b-card-actions action-collapse
								  title="Φίλτρα">
					 <validation-provider v-slot="validationContext"
										  :rules="{ required: true }">
						<b-form-group :class="getValidationClass(validationContext)"
									  :label="$t('generic.client')"
						>
						   <v-select v-model="form.contactId"
									 :options="$store.getters.getAllContacts"
									 label="surname">
							  <template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
							  <template v-slot:option="option">
								 {{ option.surname }} {{ option.name }}
							  </template>
							  <template #selected-option="option">
								 {{ option.surname }} {{ option.name }}
							  </template>
						   </v-select>
						</b-form-group>
						<b-form-invalid-feedback> {{ validationContext.errors[0] }}</b-form-invalid-feedback>

					 </validation-provider>
					 <b-form-group :label="$t('generic.from-1')"
								   label-for="start-date">
						<flat-pickr
							id="start-date"
							v-model="form.from"
							:config="{
					allowInput: true,
                  altInput: true,
                  altFormat: 'd-m-Y',
                  dateFormat: 'Y-m-d',
                }"
							:value="null"
							class="form-control"
						>
						</flat-pickr>
					 </b-form-group>
					 <b-form-group :label="$t('generic.to-1')"
								   label-for="end-date">
						<flat-pickr
							id="end-date"
							v-model="form.to"
							:config="{
					allowInput: true,
                  altInput: true,
                  altFormat: 'd-m-Y',
                  dateFormat: 'Y-m-d',
                }"
							:value="null"
							class="form-control"
						>
						</flat-pickr>
					 </b-form-group>
					 <b-button :disabled="invalid"
							   block
							   class="mt-2"
							   variant="outline-primary"
							   @click="generateReport">
						<span>{{ $t("generic.view-results") }}</span>
					 </b-button>
					 <b-button block
							   variant="flat-warning"
							   @click="reset">
						Καθαρισμός
					 </b-button>
				  </b-card-actions>
				  <!-- END: Filters -->
			   </b-col>

			   <b-col lg="9">
				  <!--Πίνακας αποτελεσμάτων -->
				  <b-card no-body>
					 <b-row v-if="showPdf"
							style="height: 100vh">


						<vue-pdf-app :pdf="$store.getters.getReport"
									 class="w-100 h-100" />


					 </b-row>

				  </b-card>
				  <!--Πίνακας αποτελεσμάτων -->
			   </b-col>

			</b-row>
		 </div>
	  </validation-observer>
   </div>
</template>

<script>
import {
   BButton,
   BButtonGroup,
   BButtonToolbar,
   BCard,
   BCardText,
   BCol,
   BForm,
   BFormGroup,
   BFormInput,
   BFormInvalidFeedback,
   BRow,
   BTable,
} from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import FormMixin from "@/Mixins/FormMixin";
import _ from "lodash";
import caldoSettings from "@/caldoSettings";
// import this to use default icons for buttons
extend('required', {
   ...required,
   message: 'Υποχρεωτικό πεδίο'

})

export default {
   components: {
	  BRow,
	  BCol,
	  BForm,
	  BCard,
	  BCardText,
	  BFormGroup,
	  vSelect,
	  flatPickr,
	  BButton,
	  BButtonToolbar,
	  BButtonGroup,
	  BCardActions,
	  BTable,
	  BFormInput,
	  BFormInvalidFeedback,
	  ValidationObserver,
	  ValidationProvider
   },
   mixins: [FormMixin],
   data() {
	  return {
		 form: {
			contactId: null,
			from: null,
			to: null,
		 },
		 showPdf: null,
	  };
   },
   methods: {


	  reset(e) {
		 e.preventDefault();
		 this.resetField(this.form);
	  },

	  getValidationClass(validated) {
		 if ((validated.valid !== undefined && validated.validated === true))
			return !validated.valid ? 'is-invalid' : 'is-valid'
	  },
	  async generateReport() {
		 await this.$store.dispatch('fetchPdfReport', this.removeEmpty(this.form));

		 this.showPdf = true;


	  }
   },
   mounted() {
	  if (this.$route.params.userId)
		 this.form.contactId = _.find(this.$store.getters.getAllContacts, {'id': this.$route.params.userId});
   },
   async created() {
	  caldoSettings.removeBreadcrumb();
	  await this.$store.dispatch('fetchAllContacts')

	  // const selectedContact   = _.find(contacts, {'id':this.$route.params.userId})
	  //
	  // console.log(await this.$store.dispatch('fetchAllContacts'))


   },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>